// Dependências
import React, { useEffect, useRef } from "react";
import { BsFillGearFill } from "react-icons/bs";
import { FaBoxes, FaDollarSign, FaComment, FaHome } from "react-icons/fa";
import { GiCarWheel } from "react-icons/gi";
import { ImUsers } from "react-icons/im";
import { useLocation } from "react-router";

// Components
import Link from "./Link/Link";
import Logo from "../Logo/Logo";

// Context
import { useWidth, useWidthUpdate } from "./WidthProvider";

// Css
import classes from "./Navbar.module.css";


const Navbar = () => {
    const location = useLocation();

    const navbar = useRef(null);
    const setWidth = useWidthUpdate();
    const width = useWidth();

    useEffect(() => {
        const resizeListener = () =>
            setWidth(navbar.current?.offsetWidth || 'auto');

        window.addEventListener('resize', resizeListener);
        resizeListener();

        const observer = new ResizeObserver(resizeListener);
        observer.observe(navbar.current);
        return () => observer.disconnect();
    }, [navbar, setWidth]);

    return (
        <div className={classes.Navbar} ref={navbar} style={{ width: width }}>
            <header>
                <Logo />
            </header>
            <nav>
                <ul>
                    <Link to="/inicio" icon={<FaHome />}>Início</Link>
                    <Link to="/estoque/pecas" icon={<FaBoxes />} isActive={() => location.pathname.includes("/estoque")}>Meu estoque</Link>
                    <Link to="/financeiro" icon={<FaDollarSign />}>Financeiro</Link>
                    <Link to="/perguntas" icon={<FaComment />}>Perguntas</Link>
                    <Link to="/funcionarios" icon={<ImUsers />}>Funcionários</Link>
                    <Link to="/cadastro-pecas" icon={<GiCarWheel />}>Anunciar peças</Link>
                    <Link to="/configuracoes" icon={<BsFillGearFill />}>Configurações</Link>
                </ul>
            </nav>
        </div >
    );
};

export default Navbar;