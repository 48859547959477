// Dependências
import React, { memo } from "react";
import { useSelector } from "react-redux";

// Css
import classes from "./Logo.module.css";

const Logo = () => {
    const isLoading = useSelector(state => state.config.isLoading);

    return (
        <div className={classes.Container} style={isLoading ? { cursor: "wait" } : {}}>
            <img
                className={classes.Ring}
                src="/images/logo-ring.png"
                alt="Logo Unificar"
                style={isLoading ? { "--animation-speed": "6", animationDirection: "reverse" } : { "--animation-speed": "120" }}
            />
            <img className={classes.Logo} src="/images/logo.png" alt="Logo Unificar" />
        </div>
    );
};

export default memo(Logo);