// Dependências
import React, { useMemo } from 'react';

// Css
import classes from "./Checkbox.module.css";

// Helpers
import randomString from "../../../helpers/randomString";


const Checkbox = props => {
    const id = useMemo(() => randomString(), []);

    return (
        <>
            <input defaultChecked={props.defaultChecked} id={id} type="checkbox" onChange={props.onChange} />
            {props.children ? <label className={classes.Label} htmlFor={id}>{props.children}</label> : null}
        </>
    );
};

export default Checkbox;