// Dependências
import React, { useState } from "react";

// Components
import Button from "../../../components/UI/Button/Button";
import ModalCreateUsuario from "../../../components/Configuracoes/Usuarios/ModalCreateUsuario/ModalCreateUsuario";
import Table from "../../../components/UI/Table/Table";
import Usuario from "../../../components/Configuracoes/Usuarios/Usuario/Usuario";

// Css
import classes from "./UsuariosScreen.module.css";

// Hooks
import useGet from "../../../hooks/useGet";


const UsuariosScreen = () => {
    const [showModal, setShowModal] = useState(false);

    const usuarios = useGet("/usuarios", { storage: true });

    /* TODO: Opção de poder editar senha de outros usuários */
    return (
        <>
            <ModalCreateUsuario show={showModal} onClose={() => setShowModal(false)} />

            <div className={classes.Header}>
                <h2 style={{ margin: '0 0 1rem 0' }}>Gerenciar Usuários</h2>

                <Button color="green" onClick={() => setShowModal(true)}> Criar usuário</Button>
            </div>

            <Table
                empty="Você não possui nenhum usuário, cadastre um agora mesmo!"
                fill
                headers={["Nome", "Permissões"]}
                height="85vh"
                hoverble
            >
                {usuarios.filter(usuario => !usuario.permissoes.find(permissao => permissao.id === 1)).map(usuario =>
                    <Usuario key={usuario.id} usuario={usuario} />
                )}
            </Table>
        </>
    );
};

export default UsuariosScreen;