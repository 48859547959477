// Dependências
import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';

// Reducers
import authReducer from './reducers/auth';
import configurationsReducer from './reducers/configurations';
import requestsReducer from './reducers/requests';

const rootReducer = combineReducers({
    auth: authReducer,
    config: configurationsReducer,
    requests: requestsReducer
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

export default store;