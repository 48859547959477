// Dependências
import React from "react";
import { Link } from "react-router-dom";

// Css
import classes from "./CadastroOption.module.css";


const CadastroOption = ({ descricao, disabled, icon, onClick, title, to }) => {
    return (
        <li className={`${classes.Wrapper} ${disabled ? classes.Disabled : ""}`} title={title} onClick={onClick}>
            <Link className={classes.CadatroOption} to={disabled ? "/cadastro-pecas" : to}>
                <div className={classes.Icon}>{icon}</div>
                <h3>{title}</h3>
                <div className={classes.Descricao}>{descricao}</div>
            </Link>
        </li>
    );
};

export default CadastroOption;