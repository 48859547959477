// Actions
import {
    REMOVE_TOKEN,
    SAVE_TOKEN
} from "../actions/auth";


const getToken = () =>
    JSON.parse(localStorage.getItem("bearer-token")) || JSON.parse(sessionStorage.getItem("bearer-token")) || null;

const initialState = {
    token: getToken()
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_TOKEN:
            return { ...state, token: action.token };
        case REMOVE_TOKEN:
            return { ...state, token: null };
        default:
            return state;
    };
};

export default authReducer;