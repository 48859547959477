// Dependências
import React from "react";

const FinanceiroScreen = () => {
    return (
        <div>
            FinanceiroScreen
        </div>
    );
};

export default FinanceiroScreen