import { CREATE_REQUEST } from "../actions/requests";
import { REMOVE_TOKEN } from "../actions/auth";

const requestsReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_REQUEST:
            const requests = { ...state };
            requests[action.key] = action.data;
            return { ...requests };
        case REMOVE_TOKEN:
            return {};
        default:
            return state;
    };
};

export default requestsReducer;