// Dependências
import React from "react";
import { Link } from "react-router-dom";

// Css
import classes from "./ColLink.module.css";


const ColLink = ({ children, to }) => {
    return (
        <td className={classes.Wrapper}>
            <Link className={classes.Link} to={to}>
                {children}
            </Link>
        </td>
    );
};

export default ColLink;