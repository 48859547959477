// Dependências
import React, { useContext } from 'react';

// Helpers
import useLocalStorage from '../../hooks/useLocalStorage';


const WidthContext = React.createContext();
const WidthUpdateContext = React.createContext();

export const useWidth = () => useContext(WidthContext);
export const useWidthUpdate = () => useContext(WidthUpdateContext);

export const WidthProvider = ({ children }) => {
    const [width, setWidth] = useLocalStorage('navbar-width', 'auto');

    const resizeWidth = newWidth =>
        setWidth(newWidth);

    return (
        <WidthContext.Provider value={width}>
            <WidthUpdateContext.Provider value={newWidth => resizeWidth(newWidth)}>
                {children}
            </WidthUpdateContext.Provider>
        </WidthContext.Provider>
    );
};