// Dependências
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from "react-router-dom";

// Actions
import * as configurationActions from './store/actions/configurations';

// Components
import Screens, { PATHS } from './screens/index';
import Navbar from './components/Navbar/Navbar';
import WhatsappButton from './components/WhatsappButton/WhatsappButton';

// Css
import classes from './App.module.css';

// Hooks
import useAxios from './hooks/useAxios';
import useGet from './hooks/useGet';
import { useWidth } from './components/Navbar/WidthProvider';

const App = () => {
    const createAcessoReq = useAxios("POST /usuarios/acesso", { notChangeLoading: true, noErrorHandler: true });
    const dispatch = useDispatch();
    const location = useLocation();

    const isLoading = useSelector(state => state.config.isLoading);
    const fontSize = useSelector(state => state.config.fontSize);
    const theme = useSelector(state => state.config.theme);
    const token = useSelector(state => state.auth.token);
    const width = useWidth();

    const serverConfigs = useGet("/server-configs", { storage: true });
    useGet(token ? "/usuario" : null, { storage: true });


    // True se o usuário estiver em uma tela que apenas usuário não logados podem acessar
    const isNotAuthenticatableScreen = useMemo(() => {
        return PATHS.filter(path => path.authentication === false).map(path => path.nome).includes(location.pathname);
    }, [location]);

    // Sempre que mudar de tela, envia para API a tela que o usuário está
    useEffect(() => {
        if (!isNotAuthenticatableScreen && token)
            createAcessoReq({
                body: {
                    url: location.pathname,
                    fontSize: fontSize,
                    theme: theme
                }
            });
    }, [createAcessoReq, isNotAuthenticatableScreen, fontSize, location, theme, token]);

    // Altera o título do documento quando troca de página
    useEffect(() => {
        let newTitle = PATHS.find(path => path.nome === location.pathname)?.title;
        if (!newTitle) newTitle = PATHS.find(path => path.nome === `/${location.pathname.split("/")[1]}`)?.title || "Unificar";
        document.title = newTitle;
    }, [location])

    return (
        <div className={`${classes.App} ${isLoading ? classes.Loading : ""}`}>
            <WhatsappButton />

            {serverConfigs.status?.id === 2 ? <Redirect to="/em-manutencao" /> : null}
            {isNotAuthenticatableScreen ? null : <Navbar />}
            <div className={isNotAuthenticatableScreen ? "" : classes.ScreensContainer} style={{ paddingLeft: isNotAuthenticatableScreen ? 0 : width }}>
                <Screens />
            </div>

            <div style={{ position: "fixed", bottom: "0", right: "0", zIndex: "100" }}>
                <button onClick={() => dispatch(configurationActions.setIsLoading(!isLoading))}>
                    isLoading: {isLoading ? "true" : "false"}
                </button>
                <button onClick={() => dispatch(configurationActions.setTheme(theme === "dark" ? "light" : "dark"))}>
                    Theme: {theme}
                </button>
            </div>
        </div>
    );
};

export default App;