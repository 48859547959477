// Dependências
import React from 'react';

// Css
import classes from './WhatsappButton.module.css';


const WhatsappButton = () => {
    return (
        <a
            title="Fale conosco"
            className={classes.WhatsappButton}
            href={`https://wa.me/5545999463628`}
            target="_blank"
            rel="noopener noreferrer"
        >
            <img src="/images/whatsapp-logo.png" width="45px" alt="Botão do Whatsapp" />
        </a>
    );
};

export default WhatsappButton;