import Accents from "remove-accents";

export const alphaNumeric = value => {
    try {
        return Accents.remove(value.toString()).replace(/[^A-Za-z0-9]/g, "").toLowerCase().trim();
    } catch (error) {
        console.log(error);
        return "Not a value";
    };
};


export const data = (value, options = {}) => {
    try {
        const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

        let dia = "";
        let mes = "";
        let ano = "";

        if (value.length > 10) value = value.slice(0, 10);

        let arrData = value.toString().split("-");
        if (arrData.length !== 3) arrData = value.toString().split("/");
        if (arrData.length !== 3) return "Not a date";
        mes = arrData[1];

        if (arrData[0].length === 4) {
            ano = arrData[0];
            dia = arrData[2];
        } else if (arrData[2].length === 4) {
            ano = arrData[2];
            dia = arrData[0];
        } else return "Not a date";

        if (parseInt(dia) < 10) dia = `0${parseInt(dia)}`;
        if (parseInt(mes) < 10) mes = `0${parseInt(mes)}`;

        if (options.convertMonthToString) {
            mes = meses[parseInt(mes) - 1];
            ano = ano.replace('20', '');
        };

        return options.onlyMonthAndYear ? `${mes}/${ano}` : `${dia}/${mes}/${ano}`;
    } catch (error) {
        console.log(error);
        return "Not a date";
    };
};

export const order = value => {
    try {
        value = Accents.remove(value.toString().trim().toLowerCase()).replace((/\s+/g), '-').replace(/[^a-z0-9-\s]+/g, '');

        switch (value) {
            case 'ano':
                return 'nome-ano'
            case 'codigo':
                return 'id'
            case 'data-de-cadastro':
                return 'data-criacao'
            case 'marca':
                return 'nome-marca'
            case 'modelo':
                return 'nome-modelo'
            case 'peca':
                return 'nome-arquetipo'
            default:
                return value;
        };
    } catch (error) {
        console.log(error);
        return "id";
    };
};