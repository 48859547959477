// Dependências
import React from "react";
import { AiFillCar, AiOutlineBarChart } from "react-icons/ai";
import { GiCarWheel } from "react-icons/gi";
import { NavLink } from "react-router-dom";

// Css
import classes from "./Navbar.module.css";


const Navbar = () => {
    return (
        <nav className={classes.Navbar}>
            <ul className={classes.NavList}>
                <NavLink className={classes.NavItem} activeClassName={classes.ActiveNavItem} to="/estoque" exact>
                    <AiOutlineBarChart className={classes.NavIcon} /> <span>Dados</span>
                </NavLink>
                <NavLink className={classes.NavItem} activeClassName={classes.ActiveNavItem} to="/estoque/sucatas">
                    <AiFillCar className={classes.NavIcon} /> <span>Sucatas</span>
                </NavLink>
                <NavLink className={classes.NavItem} activeClassName={classes.ActiveNavItem} to="/estoque/pecas">
                    <GiCarWheel className={classes.NavIcon} /> <span>Peças</span>
                </NavLink>
            </ul>
        </nav>
    );
};

export default Navbar;