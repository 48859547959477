// Dependências
import React, { useEffect, useState, useMemo } from 'react';

// Css
import classes from "./Input.module.css";

// Helpers
import randomString from "../../../helpers/randomString";


const Input = props => {
    const [inputValue, setInputValue] = useState(props.defaultValue || props.value);
    const id = useMemo(() => randomString(), []);
    const style = {
        marginTop: props.title ? "1rem" : "",
        marginBottom: props.title ? "1rem" : "",
        width: props.width ? props.width : "",
        ...props.style
    }

    // Ao mudar value, seta o inputValue
    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    const onChangeHandler = event => {
        if (props.replace) {
            let replacedValue = event.target.value.replace(props.replace, '');

            if (replacedValue !== event.target.value) {
                event.target.value = replacedValue;
                return;
            };
        };

        if (props[event._reactName])
            props[event._reactName](event);
    };

    return (
        <div className={`${classes.InputGroup} ${props.className || ""}`} style={style} >
            <input
                id={id}
                className={`${classes.Input} ${props.invalid ? classes.Invalid : ""}`}
                defaultValue={props.defaultValue}
                maxLength={props.maxLength}
                name={props.name}
                onBlur={onChangeHandler}
                onChange={onChangeHandler}
                placeholder={props.placeholder || props.title}
                style={{ borderTopLeftRadius: props.title ? "0" : "", paddingRight: props.icon ? "3rem" : "" }}
                onKeyDown={event => event.key === "Enter" && !props.enterHandler ? event.target.blur() : null}
                title={props.title || props.placeholder}
                type={props.type || "text"}
                value={props.value !== undefined ? inputValue : undefined}
            />
            {props.icon ? <span className={classes.Icon} onClick={props.onIconClick}>{props.icon}</span> : null}
            {props.title ? (
                <label className={classes.Label}>
                    {props.title}
                </label>
            ) : null}
        </div>
    );
};

export default Input;