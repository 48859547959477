// Dependências
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
/* import reportWebVitals from './reportWebVitals'; */
import * as serviceWorker from "./serviceWorker";

// Providors
import { WidthProvider } from './components/Navbar/WidthProvider';

// Components
import App from './App';
import store from './store/store';

// Css
import "./index.css";


const app = (
	<Provider store={store}>
		<BrowserRouter basename="/">
			<StrictMode>
				<WidthProvider>
					<App />
				</WidthProvider>
			</StrictMode>
		</BrowserRouter>
	</Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();

/* reportWebVitals(console.log) */
/* reportWebVitals(); */