// Dependências
import * as yup from 'yup';


export const email = value => {
    const schema = yup.string().email().required();
    try {
        schema.validateSync(value);
        return true;
    } catch (error) {
        return false;
    };
};

export const name = value => {
    const schema = yup.string().min(6).max(60).required();
    try {
        schema.validateSync(value);
        return true;
    } catch (error) {
        return false;
    };
};

export const password = value => {
    const schema = yup.string().min(4).max(60).required();
    try {
        schema.validateSync(value);
        return true;
    } catch (error) {
        return false;
    };
};