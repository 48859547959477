// Dependências
import React from "react";

const FuncionariosScreen = () => {
    return (
        <div>
            FuncionariosScreen
        </div>
    );
};

export default FuncionariosScreen;