// Dependências
import React, { useEffect, useState } from 'react';
import { BsDownload } from 'react-icons/bs';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useSelector } from 'react-redux';

// Components
import LoadingRows from "./LoadingRows/LoadingRows";
import Spinner from "../Spinner/Spinner";

// Css
import classes from "./Table.module.css";

// helpers
import { order as formatarOrder } from "../../../helpers/formatar";
import randomString from "../../../helpers/randomString";

const Table = props => {
    const isLoading = useSelector(state => state.config.isLoading);

    const [isTableLoading, setIsTableLoading] = useState(isLoading);
    const [order, setOrder] = useState(props.headers[0] || "");
    const [orderType, setOrderType] = useState('DESC');

    useEffect(() => {
        if (props.isLoading) setIsTableLoading(true);
        else if (isLoading && !props.children?.length) setIsTableLoading(true);
        else setIsTableLoading(false);
    }, [isLoading, props.children, props.isLoading]);


    const headerClickHandler = header => {
        if (props.onOrder) {
            if (header === order) {
                let newOrderType = orderType === 'ASC' ? 'DESC' : 'ASC';
                setOrderType(newOrderType);
                props.onOrder([formatarOrder(header), newOrderType]);
            } else {
                setOrderType('DESC');
                setOrder(header);
                props.onOrder([formatarOrder(header), 'DESC']);
            };
        };
    };

    const tableClasses = [
        props.hoverble ? classes.Hoverble : "",
        props.fill ? classes.HasFiller : "",
    ].join(" ");

    return (
        <div className={classes.Container}>
            <div
                className={classes.TableContainer}
                style={{
                    height: props.height || "100%",
                    overflow: props.isLoading ? "hidden" : "",
                    "--max-width": props.maxSize ? `${props.maxSize}ch` : "",
                    textAlign: props.alignCenter ? "center" : "left"
                }}
                onScroll={event => {
                    if (props.onScrollBottom && parseInt(event.target.scrollHeight) - parseInt(event.target.scrollTop) <= parseInt(event.target.clientHeight))
                        props.onScrollBottom(event);

                    if (props.onScroll) props.onScroll(event);
                }}
            >
                <table className={tableClasses} style={{ height: !props.children?.length && props.empty ? "100%" : "auto" }}>
                    {props.headers?.length ? (
                        <thead>
                            {props.title ? (
                                <tr className={classes.Title} style={{ textAlign: "left" }}>
                                    <th colSpan={props.headers.length}>
                                        {props.title}
                                        {props.downloadable && props.children?.length ? (
                                            <BsDownload
                                                className={classes.DownloadIcon}
                                                title="Baixar informações"
                                                onClick={() => { }} />
                                        ) : null}
                                    </th>
                                </tr>
                            ) : null}
                            <tr className={classes.Header}>
                                {props.headers.map(header =>
                                    <th
                                        key={randomString()}
                                        onClick={() => headerClickHandler(header)}
                                        style={{ cursor: props.onOrder ? 'pointer' : 'default' }}
                                    >
                                        {isTableLoading ? <div style={{ height: "1.4rem" }} /> : (
                                            <>
                                                {header}
                                                {props.onOrder && props.children?.length ? header === order ? orderType === 'ASC' ? <IoIosArrowUp className={classes.Icon} /> : <IoIosArrowDown className={classes.Icon} /> : null : null}
                                            </>
                                        )}
                                    </th>
                                )}
                            </tr>
                        </thead>
                    ) : null}


                    {isTableLoading ? (
                        <LoadingRows className={classes.Warning} colLength={props.headers?.length || 1} />
                    ) : props.children?.length ? props.multipleBody ? (
                        props.children
                    ) : (
                        <tbody>
                            {props.children}
                        </tbody>
                    ) : (
                        <tbody className={classes.Warning}>
                            <tr>
                                <td colSpan={props.headers?.length || 1}>
                                    {<h3>{props.empty}</h3>}
                                </ td>
                            </tr>
                        </tbody>
                    )}
                </table>

                {props.fill && props.children?.length <= 10 ? <div className={classes.Filler} /> : null}
            </div>

            {props.isLoadingBottom ? (
                <div className={classes.BottomSpinner}>
                    <Spinner />
                </div>
            ) : null}
        </div>
    );
};

export default Table;