
// Dependências
import React from "react";

const ManutencaoScreen = () => {
    return (
        <div style={{ textAlign: 'center', padding: "4rem" }}>
            <div className="row">
                <div className="col-12 pb-4">
                    <img src="/images/logo.png" style={{ width: "8rem" }} alt="Unificar" />
                </div>
            </div>
            <div>
                <h1>Em manutenção</h1>
                <h4>Estamos finalizando um novo pacote de upgrades.</h4>
                <h4>Retomaremos as atividades em breve.</h4>
            </div>
        </div>
    );
};

export default ManutencaoScreen;