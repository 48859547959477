// Dependências
import React from "react";

// Hooks
import useGet from "../../../../hooks/useGet";


const SucataScreen = ({ match }) => {
    const idSucata = match.params.id;

    const sucata = useGet(`/sucatas/${idSucata}`);

    console.log(sucata)
    return (
        <div>
            {idSucata}
        </div>
    );
};

export default SucataScreen;