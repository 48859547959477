// Dependências
import React from "react";
import { Switch, Route } from "react-router";

// Components
import Navbar from "../../components/Estoque/Navbar/Navbar";

// Css
import classes from "./EstoqueScreen.module.css";

// Hooks
import { useWidth } from "../../components/Navbar/WidthProvider";

// Screens
import EstoquePecasScreen from "./Pecas/PecasScreen";
import EstoqueSucatasScreen from "./Sucatas/SucatasScreen";


const EstoqueScreen = () => {
    const width = useWidth();

    return (
        <div className={classes.Wrapper} style={{ marginLeft: width }}>
            <Navbar />
            <div className={classes.Container}>
                <>
                    <Switch>
                        <Route path="/estoque/pecas" component={EstoquePecasScreen} />
                        <Route path="/estoque/sucatas" component={EstoqueSucatasScreen} />
                    </Switch>
                </>
            </div>
        </div>
    );
};

export default EstoqueScreen;