// Dependências
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from 'react-icons/ai';

// Components
import Input from "../../../components/UI/Input/Input";
import Sucata from "../../../components/Estoque/Sucatas/Sucata/Sucata";
import Table from "../../../components/UI/Table/Table";

// Hooks
import useGet from "../../../hooks/useGet";
import useSessionStorage from "../../../hooks/useSessionStorage";


const EstoqueSucatasScreen = () => {
    const [search, setSearch] = useSessionStorage("estoque-sucata-search", "");
    const [offset, setOffset] = useState(0);
    const [order, setOrder] = useState('id');
    const [orderType, setOrderType] = useState('DESC');

    const [isLoadingBottom, setIsLoadingBottom] = useState(true);

    const [sucatas, setSucatas] = useState([]);

    // Altera os dados do request, resetando o offset e os dados acumulados
    const changeRequestHandler = (key, value) => {
        setOffset(0);
        setSucatas([]);

        switch (key) {
            case "order":
                setOrder(value);
                break;
            case "order-type":
                setOrderType(value);
                break;
            case "search":
                setSearch(value);
                break;
            default:
                return;
        };
    };

    const resSucatas = useGet(`/sucatas?search=${search}&offset=${offset}&quantity=50&order=${order}&order_type=${orderType}`);

    // Quando resSucatas mudar, adiciona os novos dados com as sucatas antigas
    useEffect(() => {
        setIsLoadingBottom(false);
        setSucatas(prevPecas => [...prevPecas, ...resSucatas.filter(resPeca => !prevPecas.find(prevPeca => prevPeca.id === resPeca.id))]);
    }, [resSucatas]);

    return (
        <>
            <div style={{ padding: ".5rem 0", display: "flex", justifyContent: "space-between" }}>
                <Input
                    defaultValue={search}
                    width="70%"
                    icon={<AiOutlineSearch />}
                    maxLength="80"
                    name="busca-sucatas"
                    placeholder={`Buscar por sucatas no meu estoque`}
                    onBlur={event => (event.target.value.length > 2 || event.target.value.length === 0) && event.target.value !== search ? changeRequestHandler('search', event.target.value) : null}
                />
            </div>
            <div>
                <Table
                    fill
                    hoverble
                    height="80vh"
                    isLoadingBottom={isLoadingBottom}
                    onOrder={([newOrder, newOrderType]) => {
                        changeRequestHandler('order', newOrder);
                        changeRequestHandler('order-type', newOrderType);
                    }}
                    onScrollBottom={() => {
                        if (resSucatas.length) {
                            setIsLoadingBottom(true);
                            setOffset(prevOffset => prevOffset + 1)
                        };
                    }}
                    headers={["Código", "Marca", "Modelo", "Ano", "Data de Cadastro"]}
                    empty={search ? `Nenhum resultado encontrado para "${search}"` : "Nenhuma sucata foi encontrada no seu estoque :("}
                >
                    {sucatas.map(sucata => <Sucata key={sucata.id} sucata={sucata} />)}
                </Table>
            </div>
        </>
    );
};

export default EstoqueSucatasScreen;