// Dependências
import React from "react";

// Css
import classes from './Textarea.module.css';


const Textarea = props => {
    return (
        <div className={classes.TextareaGroup}>
            <textarea
                className={classes.Textarea}
                defaultValue={props.defaultValue}
                maxLength={props.maxLength}
                placeholder={props.placeholder || props.title}
                rows={props.rows || "2"}
                size="90%"
                style={{ "--rows-quantity": props.rows || "2" }}
                onBlur={props.onBlur}
            />
            {props.title ? (
                <label className={classes.Label}>
                    {props.title}
                </label>
            ) : null}
        </div>
    );
};

export default Textarea;