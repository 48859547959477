// Cores
import themes from "../../assets/themes";

export const SET_FONT_SIZE = "SET_FONT_SIZE";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_THEME = "SET_THEME";

export const setTheme = theme => {
    return (dispatch, getState) => {
        if (!Object.keys(themes).includes(theme)) return;

        const state = getState();
        const fontSize = state.config.theme;

        localStorage.setItem("theme", JSON.stringify(theme));
        document.getElementById('html').setAttribute('style', themes[theme] + `font-size:${fontSize};`);

        try {
            dispatch({ type: SET_THEME, theme: theme });
        } catch (error) {
            console.log(error);
        };
    };
};

export const setFontSize = fontSize => {
    return (dispatch, getState) => {
        const state = getState();
        const theme = state.config.theme;
        localStorage.setItem("font-size", JSON.stringify(fontSize));
        document.getElementById('html').setAttribute('style', themes[theme] + `font-size:${fontSize};`);

        try {
            dispatch({ type: SET_FONT_SIZE, fontSize: fontSize });
        } catch (error) {
            console.log(error);
        };
    };
};

export const setIsLoading = isLoading => {
    return dispatch => {
        try {
            dispatch({ type: SET_IS_LOADING, isLoading: isLoading });
        } catch (error) {
            console.log(error);
        };
    };
};