export const SAVE_TOKEN = "SAVE_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";

//const BASE_URL = process.env.REACT_APP_BASE_URL;

// Apaga o token do localstorage e envia request de logout
export const removeToken = () => {
    return (dispatch, getState) => {
        /* const token = getState().auth.token;
        const logoutReq = async () => await fetch(`${BASE_URL}/logout`, { headers: { Authorization: token } });

        logoutReq(); */
        localStorage.removeItem("bearer-token");
        sessionStorage.removeItem("bearer-token");

        dispatch({ type: REMOVE_TOKEN });
    };
};

// Recebe o access_token da API se o login for válido
export const saveToken = (token, rememberMe = true) => {
    return dispatch => {
        if (!token) return;

        token = `Bearer ${token}`;

        if (rememberMe) localStorage.setItem("bearer-token", JSON.stringify(token));
        else sessionStorage.setItem("bearer-token", JSON.stringify(token));

        dispatch({ type: SAVE_TOKEN, token: token });
    };
};