// Dependências
import axios from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { setIsLoading } from "../store/actions/configurations";
import { createRequest } from "../store/actions/requests";

// Helpers
import { error as alertError } from "../helpers/sweetAlert";


const BASE_URL = process.env.REACT_APP_BASE_URL;
/* const expectedStatusCodes = [
    { method: "DELETE", status: [200] },
    { method: "GET", status: [200] },
    { method: "PATCH", status: [200] },
    { method: "POST", status: [201] },
    { method: "PUT", status: [200] },
]; */

const useAxios = (methodUrl, options = { notChangeLoading: false, notSendToken: false, storage: false, sendFormData: false, formDataName: "" }) => {
    const dispatch = useDispatch();

    const token = useSelector(state => state.auth.token);
    const prevRequest = useSelector(state => state.requests[methodUrl]);
    const unshiftRequest = useSelector(state => state.requests[options.unshift]);
    const removeRequest = useSelector(state => state.requests[options.remove?.request]);

    const method = methodUrl.split(" ")[0];
    const url = methodUrl.split(" ")[1];


    return useCallback((requestOptions = { body: undefined, files: {} }) => {
        // Se a option storage for true, ao invés de fazer um novo request irá retornar o que um request igual anterior retornou.
        if (options.storage && prevRequest) return prevRequest;

        if (!options.notChangeLoading)
            dispatch(setIsLoading(true));

        // Criação do formData
        let formData = new FormData();
        if (options.sendFormData) {
            if (requestOptions.body) {
                if (options.formDataName)
                    formData.append(options.formDataName, JSON.stringify(requestOptions.body));
                else
                    for (const key in requestOptions.body) formData.append(key, JSON.stringify(requestOptions.body[key]));
            };

            for (const key in requestOptions.files) formData.append(key, requestOptions.files[key]);
        };

        return new Promise(async resolve => {
            axios({
                method: method,
                url: `${BASE_URL}${url}`,
                headers: {
                    'Content-Type': options.sendFormData ? `multipart/form-data; boundary=${formData._boundary}` : `application/json; charset=utf-8`,
                    'authorization': options.notSendToken ? undefined : token
                },
                data: options.sendFormData ? formData : requestOptions.body
            }).then(result => {
                if (options.storage) dispatch(createRequest(methodUrl, result.data));

                // Esta opção sobrescreve um outro request (Ex: PUT /usuario retorna GET /usuario, portanto o PUT /usuario sobrescreve o GET /usuario)
                if (options.overwrite) dispatch(createRequest(options.overwrite, result.data));

                // Esta opção coloca o result.data no começo de uma outra requisição feita
                if (options.unshift) dispatch(createRequest(options.unshift, [result.data, ...unshiftRequest]));

                // Apaga um ID dentro de um request
                if (options.remove) dispatch(createRequest(options.remove.request, removeRequest.filter(item => item.id !== options.remove.id)))


                resolve({ data: result.data, status: result.status });
            }).catch(error => {
                if (error.message === "Network Error") alertError({ title: "Erro de Conexão", text: "Por favor, verifique sua conexão com a internet." });
                if (!options.noErrorHandler) {
                    if (error.status) alertError(error.message);
                    else alertError();
                };
                resolve({ message: error.response?.data?.message, status: error.response?.status, cause: error.response?.data?.cause });
            }).finally(() => {
                if (!options.notChangeLoading) dispatch(setIsLoading(false));
            });
        });
    }, [token, prevRequest, removeRequest, unshiftRequest]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useAxios;