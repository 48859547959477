// Dependências
import React from 'react';

// Css
import classes from "./Dropdown.module.css";

const Dropdown = props => {
    return props.list?.length ? (
        <select className={classes.Dropdown} onChange={props.onChange} style={{ width: props.width }} defaultValue={props.defaultValue}>
            {props.list.map(item =>
                <option
                    key={item.id}
                    className={classes.Option}
                    value={item.id}
                >
                    {item.nome}
                </option>
            )}
        </select>
    ) : null;
};

export default Dropdown