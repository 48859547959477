// Dependências
import React, { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaUsersCog } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions
import { setFontSize, setTheme } from "../../store/actions/configurations";
import { removeToken } from "../../store/actions/auth";

// Components
import Button from "../../components/UI/Button/Button";
import Dropdown from '../../components/UI/Dropdown/Dropdown';
import ImagemConta from '../../components/Configuracoes/ImagemConta/ImagemConta';
import Input from '../../components/UI/Input/Input';
import Table from "../../components/UI/Table/Table";
import Textarea from '../../components/UI/Textarea/Textarea';
import Toggle from '../../components/UI/Toggle/Toggle';
import UpdateSenha from "../../components/Configuracoes/UpdateSenha/UpdateSenha";
import Vinculo from '../../components/Configuracoes/Vinculo/Vinculo';

// Css
import classes from './ConfiguracoesScreen.module.css';

// Hooks
import useAxios from "../../hooks/useAxios";
import useGet from "../../hooks/useGet";


const ConfiguracoesScreen = () => {
    const dispatch = useDispatch();

    const patchConta = useAxios("PATCH /contas", { overwrite: "GET /usuario", sendFormData: true, formDataName: "conta" });

    const theme = useSelector(state => state.config.theme);
    const fontSize = useSelector(state => state.config.fontSize);
    const usuario = useSelector(state => state.requests["GET /usuario"]);

    const [displayUpdateSenha, setDisplayUpdateSenha] = useState(false);

    const fretes = useGet("/anuncios/fretes");


    return !usuario ? null : (
        <>
            <header className={classes.Header}>
                <h1>{usuario.conta.nome}</h1>
                <h1>-</h1>
                <h1>{usuario.nome}</h1>
            </header>

            <div className={classes.ImageAndTable}>
                <div className={classes.ImageContainer}>
                    <ImagemConta
                        imagem={usuario.conta.configuracoes.imagem}
                        updateImagem={novaImagem => patchConta(novaImagem)}
                    />
                </div>

                <div className={classes.Vinculos}>
                    <Table
                        alignCenter
                        title="Vínculos"
                        empty={<span>Você ainda não possui nenhum vínculo, clique em <span style={{ color: "var(--vermelho)" }}>Novo Vínculo</span> para começar a criar seus anúncios.</span>}
                        headers={["Plataforma", "Conta", "Data de Criação"]}
                    >
                        {usuario.conta.vinculos.map(vinculo =>
                            <Vinculo
                                key={vinculo.id}
                                vinculo={vinculo} />
                        )}
                    </Table>

                    <div style={{ marginTop: "1rem", textAlign: "right" }}>
                        <a href={`https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=5330184019012924&redirect_uri=https://sistemaunificar.com.br/configuracoes/vinculos/mercado-livre`}>
                            <Button color="green">Novo vínculo</Button>
                        </a>
                    </div>
                </div>
            </div>

            {usuario.conta.tipo.id === 3 || usuario.conta.tipo.id === 4 ? (
                <ul className={classes.Options}>
                    <li className={classes.NotVisible}></li>

                    <li>
                        <label htmlFor="dados-ficticios-toggle" style={{ cursor: 'pointer' }}>
                            <strong>Dados fictícios</strong>
                        </label>

                        <Toggle
                            id="dados-ficticios-toggle"
                            defaultChecked={usuario.conta.configuracoes.demonstracao}
                            onChange={event => patchConta({ body: { demonstacao: event.target.checked } })}
                        />
                    </li>
                </ul>
            ) : null}

            <div>
                <h3>Meus anúncios</h3>

                <ul className={classes.Options}>
                    <li>
                        <span>Frete padrão</span>
                        <Dropdown
                            defaultValue={usuario.conta.configuracoes.frete.id}
                            list={[...fretes]}
                            onChange={event => patchConta({ body: { frete: { id: event.target.value } } })}
                        />
                    </li>
                    <li>
                        <Input
                            defaultValue={usuario.conta.configuracoes.garantia}
                            replace={/\D/g}
                            onBlur={event => Number(event.target.value) !== usuario.conta.configuracoes.garantia ? patchConta({ body: { garantia: event.target.value } }) : null}
                            title="Dias de Garantia"
                        />
                    </li>
                    <li>
                        <Textarea
                            defaultValue={usuario.conta.configuracoes.descricao}
                            maxLength={500}
                            title="Descrição padrão."
                            onBlur={event => event.target.value !== usuario.conta.configuracoes.descricao ? patchConta({ body: { descricao: event.target.value } }) : null}
                            rows="4"
                        />
                    </li>
                </ul>
            </div>

            <div>
                <h3>Preferências</h3>
                <ul className={classes.Options}>
                    <li>
                        <span>Tema</span>
                        <Dropdown
                            defaultValue={theme}
                            list={[{ id: "light", nome: "Claro" }, { id: "dark", nome: "Escuro" }]}
                            onChange={event => dispatch(setTheme(event.target.value))}
                        />
                    </li>
                    <li>
                        <span>Tamanho da fonte</span>
                        <Dropdown
                            defaultValue={fontSize}
                            list={[
                                { id: "12px", nome: "Extra pequena" },
                                { id: "14px", nome: "Pequena" },
                                { id: "16px", nome: "Padrão" },
                                { id: "18px", nome: "Grande" },
                                { id: "20px", nome: "Extra grande" },
                            ]}
                            onChange={event => dispatch(setFontSize(event.target.value))}
                        />
                    </li>
                </ul>
            </div>

            <div>
                <h3>Segurança</h3>
                <ul className={classes.Options}>
                    <li className={classes.AlterarSenha}>
                        <button className={classes.Button} onClick={() => setDisplayUpdateSenha(prevDisplaySenha => !prevDisplaySenha)}>
                            <span>Alterar senha</span>
                            <IoMdArrowDropdown size="1.8rem" />
                        </button>

                        <UpdateSenha display={displayUpdateSenha} closeUpdateSenha={() => setDisplayUpdateSenha(false)} />
                    </li>

                    {usuario.permissoes.find(permissao => permissao.id === 2) ? (
                        <li>
                            <Link className={classes.Button} to="/configuracoes/usuarios">
                                <span>Gerenciar usuários</span>
                                <FaUsersCog size="1.8rem" />
                            </Link>
                        </li>
                    ) : null}
                </ul>

                <div style={{ paddingTop: "5rem", textAlign: "center" }}>
                    <Button color="red" onClick={() => dispatch(removeToken())}> Logout</Button>
                </div>
            </div>
        </>
    );
};

export default ConfiguracoesScreen;