// Dependências
import React from "react";
import { AiFillCar, AiOutlineFileExcel } from "react-icons/ai";
import { FaTools } from "react-icons/fa";

// Components
import CadastroOption from "../../components/CadastroPecas/CadastroOption/CadastroOption";

// Css
import classes from "./CadastroPecasScreen.module.css";

// Helpers
import sweetAlert from "../../helpers/sweetAlert";


const CadastroPecasScreen = () => {
    const arquivoDetranAlert = () => {
        sweetAlert({
            title: "Opção Indisponível",
            text: "Esta opção está disponível apenas para desmontes do <strong>Rio Grande do Sul.</strong> Se você deseja ter acesso a este recurso, por favor entre em contato.",
            showCancelButton: false
        })
    };

    const cadastroOptions = [
        { title: "Cadastro de Sucata", to: "/cadastro-pecas/cadastro-sucata", icon: <AiFillCar />, descricao: "Cadastre uma sucata e gere anúncios para várias peças desta sucata." },
        { title: "Grupo de Peças", to: "/cadastro-pecas/grupo-pecas", icon: <FaTools />, descricao: "Cadastre várias peças de modelos diferentes, e crie anúncios para elas." },
        { title: "Arquivo do DETRAN", to: "/cadastro-pecas/arquivo-detran", icon: <AiOutlineFileExcel />, descricao: "Importe várias peças de uma vez através do arquivo Excel que o DETRAN gera.", disabled: true, onClick: arquivoDetranAlert },
    ];

    return (
        <div>
            <h1 style={{ textAlign: "center" }}>Selecione o método de cadastro</h1>
            <ul className={classes.CadatroOptionsList}>
                {cadastroOptions.map(cadastroOption =>
                    <CadastroOption
                        key={cadastroOption.title}
                        descricao={cadastroOption.descricao}
                        disabled={cadastroOption.disabled}
                        icon={cadastroOption.icon}
                        onClick={cadastroOption.onClick}
                        title={cadastroOption.title}
                        to={cadastroOption.to}
                    />
                )}
            </ul>
        </div>
    );
};

export default CadastroPecasScreen;