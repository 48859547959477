// Dependências
import React, { memo } from 'react';
import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from 'react-router-dom';

// Screens
import CadastroPecasScreen from "./CadastroPecas/CadastroPecasScreen";
import CadastroGrupoPecasScreen from "./CadastroPecas/GrupoPecas/GrupoPecasScreen";
import CadastroSucataScreen from "./CadastroPecas/CadastroSucata/CadastroSucataScreen";
import CadastroSucataSucataScreen from "./CadastroPecas/CadastroSucata/Sucata/SucataScreen";

import ConfiguracoesScreen from './Configuracoes/ConfiguracoesScreen';
import UsuariosScreen from './Configuracoes/Usuarios/UsuariosScreen';
import MercadoLivreVinculoScreen from './Configuracoes/Vinculos/MercadoLivreScreen';

import EstoqueScreen from './Estoque/EstoqueScreen';
import PecaScreen from './Estoque/Pecas/Peca/PecaScreen';
import SucataScreen from './Estoque/Sucatas/Sucata/SucataScreen';

import FinanceiroScreen from './Financeiro/FinanceiroScreen';

import FuncionariosScreen from './Funcionarios/FuncionariosScreen';

import InicioScreen from './Inicio/InicioScreen';

import LoginScreen from './Login/LoginScreen';

import ManutencaoScreen from './Manutencao/ManutencaoScreen';


// Aqui são definidas as propriedades de cada página
// authentication: Se verdadeira, a página só poderá ser acessada por usuários logados, e o menu lateral será exibido
// accessPermissions: Um array de IDs das permissões que podem acessar a página
export const PATHS = [
    { nome: "/cadastro-pecas", title: "Cadastro de Peças - Unificar" },
    { nome: "/configuracoes", title: "Configurações - Unificar" },
    { nome: "/configuracoes/usuarios", title: "Gerenciar usuários - Unificar" },
    { nome: "/configuracoes/vinculos/mercado-livre", title: "Aguarde..." },
    { nome: "/em-manutencao", title: "Estamos em manutenção!", authentication: false },
    { nome: "/estoque", title: "Meu Estoque - Unificar" },
    { nome: "/novo-estoque", title: "Meu Estoque - Unificar" },
    { nome: "/financeiro", title: "Financeiro - Unificar" },
    { nome: "/funcionarios", title: "Funcionários - Unificar" },
    { nome: "/inicio", title: "Unificar" },
    { nome: "/login", title: "LOGIN - Unificar", authentication: false },
].map(path => ({
    ...path,
    authentication: path.authentication === false ? false : true,
    accessPermissions: path.accessPermissions || []
}));

const CadastroPecasNavigator = () => {
    const CadastroGrupoPecasNavigator = () => (
        <Switch>
            <Route path="/cadastro-pecas/grupo-pecas" component={CadastroGrupoPecasScreen} />
            <Redirect to="/cadastro-pecas/grupo-pecas" />
        </Switch>
    );

    const CadastroSucataNavigator = () => (
        <Switch>
            <Route path="/cadastro-pecas/cadastro-sucata/:id" component={CadastroSucataSucataScreen} />
            <Route path="/cadastro-pecas/cadastro-sucata" component={CadastroSucataScreen} />
            <Redirect to="/cadastro-pecas/cadastro-sucata" />
        </Switch>
    );

    return (
        <Switch>
            <Route path="/cadastro-pecas/grupo-pecas" component={CadastroGrupoPecasNavigator} />
            <Route path="/cadastro-pecas/cadastro-sucata" component={CadastroSucataNavigator} />
            <Route path="/cadastro-pecas" component={CadastroPecasScreen} />
            <Redirect to="/cadastro-pecas" />
        </Switch>
    );
};

const ConfiguracoesNavigator = () => {
    return (
        <Switch>
            <Route path="/configuracoes/vinculos/mercado-livre" component={MercadoLivreVinculoScreen} />
            <Route path="/configuracoes/usuarios" component={UsuariosScreen} />
            <Route path="/configuracoes" component={ConfiguracoesScreen} />
            <Redirect to="/configuracoes" />
        </Switch>
    );
};

const EstoqueNavigator = () => {
    return (
        <Switch>
            <Route path="/estoque/pecas/:id" component={PecaScreen} />
            <Route path="/estoque/sucatas/:id" component={SucataScreen} />
            <Route path="/estoque" component={EstoqueScreen} />
            <Redirect to="/estoque" />
        </Switch>
    );
};

const ManutencaoNavigator = () => {
    const serverConfigs = useSelector(state => state.requests["GET /server-configs"]);

    return (
        <Switch>
            {serverConfigs?.status?.id === 2 ? <Route path="/em-manutencao" component={ManutencaoScreen} /> : <Redirect to="/inicio" />}
        </Switch>
    );
};


const Navigator = () => {
    const token = useSelector(state => state.auth.token);

    return !token ? (
        <Switch>
            <Route path="/em-manutencao" component={ManutencaoNavigator} />
            <Route path="/login" component={LoginScreen} />
            <Redirect to="/login" />
        </Switch>
    ) : (
        <Switch>
            <Route path="/cadastro-pecas" component={CadastroPecasNavigator} />
            <Route path="/configuracoes" component={ConfiguracoesNavigator} />
            <Route path="/em-manutencao" component={ManutencaoNavigator} />
            <Route path="/estoque" component={EstoqueNavigator} />
            <Route path="/financeiro" component={FinanceiroScreen} />
            <Route path="/funcionarios" component={FuncionariosScreen} />
            <Route path="/inicio" component={InicioScreen} />
            <Redirect to="/inicio" />
        </Switch>
    );
};

export default memo(Navigator);