// Dependências
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router";

// Helpers
import {
    error as alertError,
    success as alertSuccess
} from "../../../helpers/sweetAlert";

// Hooks
import useAxios from "../../../hooks/useAxios";


const MercadoLivreScreen = props => {
    const createVinculoReq = useAxios("POST /contas/vinculos");

    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();

    const code = query.get("code");

    const errorHandler = useCallback(errorMessage => {
        alertError(errorMessage || "Houve um erro ao tentar vincular sua conta do Mercado Livre. Se o problema persistir, por favor entre em contato.");
        props.history.push("/configuracoes");
    }, [props.history]);

    // Verifica se tem code, se tiver envia para API
    useEffect(() => {
        if (!code || code?.length < 4) errorHandler();
        else {
            createVinculoReq({ body: { plataforma: { id: 2 }, code: code } }).then(result => {
                if (result.status === 201) {
                    alertSuccess("Sua conta foi vinculada!");
                    props.history.push("/inicio");
                } else errorHandler(result.message);
            });
        };
    }, [code, createVinculoReq, errorHandler, props.history]);


    return (
        <div>
            <h3>Por favor, aguarde.</h3>
            <h4>Estamos nos conectando com a sua conta do Mercado Livre.</h4>
        </div>
    );
};

export default MercadoLivreScreen;