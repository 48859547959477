// Dependências
import React from "react";
import { NavLink } from "react-router-dom";

// Css
import classes from "./Link.module.css";

const Link = props => {
    return (
        <li className={classes.ListItem} title={props.children}>
            <NavLink to={props.to} className={classes.LinkContainer} activeClassName={classes.Active} isActive={props.isActive}>
                <div className={classes.Link}>
                    <i className={classes.Icon}>{props.icon}</i>
                    <span>{props.children}</span>
                </div>
            </NavLink>
        </li>
    );
};

export default Link;