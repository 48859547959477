//Dependências	
import React from "react";

// Css
import classes from "./LoadingRows.module.css";


const LoadingRows = ({ colLength }) => (
    <tbody className={classes.LoadingRow}>
        {[...Array(15)].map((row, index) => (
            <tr key={index}>
                {[...Array(colLength)].map((col, colIndex) => (
                    <td key={colIndex} style={{ "--default-delay": `${(index - 0.1) * 0.1}s` }}>
                        <span></span>
                    </td>
                ))}
            </tr>
        ))}
    </tbody>
);

export default LoadingRows;