// Dependências
import React, { useEffect, useState } from "react";

// Components
import Button from "../../../UI/Button/Button";
import Input from "../../../UI/Input/Input";
import Modal from "../../../UI/Modal/Modal";
import Permissoes from "../Permissoes/Permissoes";

// Helpers
import { error as alertError, sweetAlert } from "../../../../helpers/sweetAlert";
import { name as nameValidator } from "../../../../helpers/validator";

// Hooks
import useAxios from "../../../../hooks/useAxios";


const Usuario = ({ usuario }) => {
    const deleteUsuarioReq = useAxios(`DELETE /usuarios/${usuario.id}`, { remove: { request: "GET /usuarios", id: usuario.id } });
    const patchUsuarioReq = useAxios(`PATCH /usuarios/${usuario.id}`, { overwrite: "GET /usuarios" });

    const [newNome, setNewNome] = useState("");
    const [newPermissoes, setNewPermissoes] = useState([...usuario.permissoes]);
    const [hasChanged, setHasChanged] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isNomeValid, setIsNomeValid] = useState(true);

    // Seta iniciao de newPermissoes quando usuário muda
    useEffect(() => {
        setNewPermissoes([...usuario.permissoes]);
    }, [usuario]);

    // Reseta os states e fecha o modal
    const closeModal = () => {
        setNewPermissoes([...usuario.permissoes]);
        setHasChanged(false);
        setIsNomeValid(true);
        setShowModal(false);
    };

    // Confirma e apaga o usuário
    const deleteUsuario = () => {
        sweetAlert({
            title: "Tem certeza?",
            text: "Deseja mesmo excluir esse usuário? Essa ação não poderá ser revertida!",
            confirmButtonText: "Sim, excluir",
        }).then(result => {
            if (result.isConfirmed) {
                deleteUsuarioReq();
                closeModal();
            };
        });
    };

    // Verifica se o usuário realmente deseja fechar sem salvar
    const onCloseHandler = () => {
        if (hasChanged && !isLoading) {
            sweetAlert({
                title: "Sair sem salvar?",
                text: "As alterações não serão mantidas",
                confirmButtonText: "Sim, sair",
            }).then(result => { if (result.isConfirmed) closeModal() });
        } else closeModal();
    };

    const submitHandler = async () => {
        if (nameValidator(newNome) || !newNome) {
            setIsLoading(true);
            await patchUsuarioReq({
                body: {
                    nome: newNome === usuario.nome || !newNome ? undefined : newNome,
                    permissoes: [...newPermissoes]
                }
            });
            setIsLoading(false);
            closeModal();
        } else {
            alertError("O nome informado não é válido");
            setIsNomeValid(false);
        };
    };

    // Rodapé do modal
    const Footer = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button color="gray" onClick={closeModal}>Cancelar</Button>
            <Button color="red" onClick={deleteUsuario}>Excluir</Button>
            <Button color="green" onClick={submitHandler} disabled={!hasChanged || isLoading}>{isLoading ? "Carregando..." : "Salvar"}</Button>
        </div>
    );

    return (
        <>
            <tr onClick={() => setShowModal(true)}>
                <td>{usuario.nome}</td>
                <td>{usuario.permissoes.length}</td>
            </tr>

            <Modal title={usuario.nome} show={showModal} onClose={onCloseHandler} footer={Footer}>
                <div style={{ padding: "1rem 0" }}>
                    <Input
                        defaultValue={usuario.nome}
                        title="Nome"
                        invalid={!isNomeValid}
                        maxLength={60}
                        onChange={event => {
                            setHasChanged(true);
                            setNewNome(event.target.value);
                        }}
                    />

                    <Permissoes
                        selectedPermissoes={usuario.permissoes.map(permissao => permissao.id)}
                        onChange={selectedPermissao => {
                            setHasChanged(true);
                            if (newPermissoes.find(newPermissao => newPermissao.id === selectedPermissao.id))
                                setNewPermissoes(prevNewPermissoes => prevNewPermissoes.filter(prevPermissao => prevPermissao.id !== selectedPermissao.id));
                            else
                                setNewPermissoes(prevNewPermissoes => [...prevNewPermissoes, selectedPermissao]);
                        }}
                    />
                </div>
            </Modal>
        </>
    );
};

export default Usuario;