// Dependências
import React from "react";

// Css
import classes from "./Spinner.module.css";


const Spinner = props => {
    return (
        <div className={classes.Spinner} style={{ width: props.size, height: props.size }} />
    );
};

export default Spinner;