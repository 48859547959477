// Dependências
import React from "react";

// Css
import classes from "./Vinculo.module.css";

// Helpers
import { data as formatarData } from "../../../helpers/formatar";


const Vinculo = ({ vinculo }) => {
    return (
        <tr className={classes.Row}>
            <td /* style={{ padding: ".4rem 0" }} */>
                <a href={vinculo.plataforma.url} target="_blank" rel="noreferrer" title={vinculo.plataforma.nome}>
                    <img
                        className={classes.LogoPlataforma}
                        src={vinculo.plataforma.imagem}
                        alt={vinculo.plataforma.nome}
                    />
                </a>
            </td>

            <td /* style={{ padding: ".4rem 0" }} */> {vinculo.nome} </td>

            <td /* style={{ padding: ".4rem 0" }} */> {formatarData(vinculo.dataCriacao)} </td>
        </tr>
    );
};

export default Vinculo;