// Dependências
import imageCompression from 'browser-image-compression';

const compressImage = async imageFiles => {
    const options = {
        maxSizeMB: 0.9,
        maxWidthOrHeight: 1200,
        useWebWorker: true
    };

    const formatedFiles = [];
    for (let imageFile of imageFiles) {
        if (imageFile.size < options.maxSizeMB * 1000)
            return imageFile;
        else {
            try {
                let file = await imageCompression(imageFile, options);
                console.log(file)
                let src = await imageCompression.getDataUrlFromFile(imageFile);
                formatedFiles.push({ file: file, src: src });
            } catch (error) {
                console.log(error);
            };
        };
    };
    return formatedFiles;
};

const readImageFiles = async files => {
    if (!files.length) return;

    const filesArr = [];
    for (let i = 0; i < files.length; i++) filesArr.push(files[i]);

    const formatedFiles = await compressImage(filesArr);

    console.log(formatedFiles);
    return formatedFiles;
};

export default readImageFiles;