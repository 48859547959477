// Dependências
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { saveToken } from '../../store/actions/auth';

// Components
import Button from "../../components/UI/Button/Button";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import Input from "../../components/UI/Input/Input";
import Logo from "../../components/Logo/Logo";

// Css
import classes from "./LoginScreen.module.css";

// Helpers
import * as validator from "../../helpers/validator";

// Hooks
import useAxios from "../../hooks/useAxios";
import useLocalStorage from "../../hooks/useLocalStorage";


const LoginScreen = () => {
    const dispatch = useDispatch();

    const loginReq = useAxios("POST /login");
    const isLoading = useSelector(state => state.config.isLoading);

    const [nomeUsuario, setNomeUsuario] = useLocalStorage("nickname", "");
    const [email, setEmail] = useLocalStorage("email", "");
    const [senha, setSenha] = useState("");
    const [rememberMe, setRememberMe] = useState(true);

    const [isValid, setIsValid] = useState({
        nomeUsuario: true,
        email: true,
        senha: true
    });

    // Valida os campos do formulário
    const isValidHandler = () => {
        const newIsValid = {
            nomeUsuario: validator.name(nomeUsuario),
            email: validator.email(email),
            senha: validator.password(senha)
        };

        setIsValid({ ...newIsValid });

        if (!newIsValid.email || !newIsValid.senha || !newIsValid.nomeUsuario) return false;
        return true;
    };

    // Lida com o submit do formulário
    const submitHandler = async event => {
        event.preventDefault();

        if (isValidHandler()) {
            const response = await loginReq({ body: { nomeUsuario, email, senha } });

            if (response.status === 401)
                setIsValid({ nomeUsuario: false, email: false, senha: false });
            else if (response.status === 200)
                dispatch(saveToken(response.data.access_token));
        };
    };

    return (
        <div className={classes.Container}>
            <div className={classes.ImageContainer}>
                <img
                    className={classes.BackgroundImage}
                    src="./images/motor-de-carro.jpg"
                    alt="Motor de Carro" />
            </div>

            <main className={classes.Main}>
                <div className={classes.LogoContainer}>
                    <Logo />
                </div>

                <form className={classes.FormGroup} onSubmit={submitHandler}>
                    <Input
                        enterHandler
                        defaultValue={nomeUsuario}
                        invalid={!isValid.nomeUsuario}
                        maxLength="50"
                        name="nickname"
                        onChange={event => setNomeUsuario(event.target.value)}
                        placeholder="Nome de usuário"
                        title="Nome de usuário"
                        style={{ marginBottom: "3rem" }} />

                    <Input
                        enterHandler
                        defaultValue={email}
                        invalid={!isValid.email}
                        maxLength="50"
                        name="email"
                        onChange={event => setEmail(event.target.value)}
                        title="E-mail"
                        type="email"
                        style={{ marginBottom: "2rem" }} />

                    <Input
                        enterHandler
                        invalid={!isValid.senha}
                        maxLength="50"
                        name="password"
                        onChange={event => setSenha(event.target.value)}
                        title="Senha"
                        type="password" />

                    <div style={{ textAlign: "right" }}>
                        <Checkbox
                            defaultChecked={rememberMe}
                            onChange={event => setRememberMe(event.target.checked)}
                        >Lembre de mim</Checkbox>
                    </div>

                    <div style={{ padding: "3rem 0", textAlign: "center" }}>
                        <Button
                            disabled={isLoading}
                            type="submit"
                            color="green"
                        >
                            {isLoading ? "Carregando..." : "Entrar"}
                        </Button>
                    </div>
                </form>
            </main>
        </div>
    );
};

export default LoginScreen;