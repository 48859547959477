// Dependências
import React from "react";

// Hooks
import useGet from "../../../../hooks/useGet";


const PecaScreen = ({ match }) => {
    const idPeca = match.params.id;

    const peca = useGet(`/pecas/${idPeca}`);

    console.log(peca)
    return (
        <div>PecaScreen {idPeca}</div>
    );
};

export default PecaScreen;