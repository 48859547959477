// Dependências
import React, { useState } from "react";
import { useSelector } from 'react-redux';

// Components
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";

// Css
import classes from "./UpdateSenha.module.css";

// Helpers
import { password as passwordValidator } from "../../../helpers/validator";
import { success as alertSuccess } from "../../../helpers/sweetAlert";

// Hooks
import useAxios from "../../../hooks/useAxios";


const UpdateSenha = ({ closeUpdateSenha, display }) => {
    const isLoading = useSelector(state => state.config.isLoading);

    const [senha, setSenha] = useState("");
    const [novaSenha, setNovaSenha] = useState("");
    const [confirmacaoNovaSenha, setConfirmacaoNovaSenha] = useState("");

    const [errorMessage, setErrorMessage] = useState("");

    const [isValid, setIsValid] = useState({
        senha: true,
        novaSenha: true,
        confirmacaoNovaSenha: true
    });

    const updateSenhaReq = useAxios("PUT /usuarios/senha");

    const validateForm = () => {
        let formIsValid = true;
        let copiaIsValid = { ...isValid };

        if (confirmacaoNovaSenha !== novaSenha) {
            formIsValid = false;
            copiaIsValid.novaSenha = false;
            copiaIsValid.confirmacaoNovaSenha = false;
            setErrorMessage("Senhas não coincidem.");
        } else {
            if (passwordValidator(novaSenha)) {
                copiaIsValid.novaSenha = true;
                copiaIsValid.confirmacaoNovaSenha = true;
            } else {
                formIsValid = false;
                copiaIsValid.novaSenha = false;
                copiaIsValid.confirmacaoNovaSenha = false;
                setErrorMessage("A nova senha não é válida.");
            };
        };

        if (!passwordValidator(senha)) {
            copiaIsValid.senha = false;
            formIsValid = false
            setErrorMessage("Senha atual não corresponde.");
        } else copiaIsValid.senha = true;

        if (formIsValid) setErrorMessage("");
        setIsValid({ ...copiaIsValid });
        return formIsValid;
    };

    const submitHandler = event => {
        event.preventDefault();

        const formIsValid = validateForm();

        if (formIsValid) {
            updateSenhaReq({ body: { senha, novaSenha } }).then(res => {
                if (res.status !== 200) {
                    setIsValid(prevIsValid => ({ ...prevIsValid, senha: false }));
                    if (res.message) setErrorMessage(res.message);
                } else {
                    setSenha("");
                    setNovaSenha("");
                    setConfirmacaoNovaSenha("");
                    alertSuccess("Senha alterada com sucesso!");
                    closeUpdateSenha();
                };
            });
        };
    };

    return (
        <form
            className={classes.Container}
            style={{ display: display ? 'block' : 'none' }}
            onSubmit={submitHandler}
        >
            <Input
                invalid={!isValid.senha}
                onChange={event => setSenha(event.target.value)}
                title="Senha atual"
                type="password"
                value={senha}
            />

            <Input
                invalid={!isValid.novaSenha}
                onChange={event => setNovaSenha(event.target.value)}
                title="Nova senha"
                type="password"
                value={novaSenha}
            />

            <Input
                invalid={!isValid.confirmacaoNovaSenha}
                onChange={event => setConfirmacaoNovaSenha(event.target.value)}
                title="Confirmar nova senha"
                type="password"
                value={confirmacaoNovaSenha}
            />

            {errorMessage ? <span className={classes.Error}>{errorMessage}</span> : null}
            <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
                <Button
                    disabled={isLoading}
                    color="green"
                    type="submit"
                >
                    {isLoading ? "Carregando..." : "Salvar"}
                </Button>
            </div>
        </form>
    );
};

export default UpdateSenha;