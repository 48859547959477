// Dependências
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { setIsLoading } from "../store/actions/configurations";
import { createRequest } from "../store/actions/requests";

// Helpers
import { error as alertError } from "../helpers/sweetAlert";


const BASE_URL = process.env.REACT_APP_BASE_URL;

const useGet = (url, options = { storage: false }) => {
    const dispatch = useDispatch();

    const token = useSelector(state => state.auth.token);
    const prevRequest = useSelector(state => state.requests[`GET ${url}`]);

    const [response, setResponse] = useState([]);

    const request = useCallback(() => {
        // Se a option storage for true, ao invés de fazer um novo request irá retornar o que um request igual anterior retornou.
        if (options.storage && prevRequest) {
            setResponse(prevRequest);
            return;
        };

        dispatch(setIsLoading(true));
        axios({
            method: "GET",
            url: `${BASE_URL}${url}`,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'authorization': token
            }
        }).then(result => {
            if (options.storage) dispatch(createRequest(`GET ${url}`, result.data));
            setResponse(result.data);
        }).catch(error => {
            if (error.message === "Network Error") alertError({ title: "Erro de Conexão", text: "Por favor, verifique sua conexão com a internet." });
            else if (error.status) alertError(error.message);
            else alertError();
        }).finally(() => dispatch(setIsLoading(false)));
    }, [url, prevRequest]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (url) request();
        else setResponse([]);
    }, [request, options.doRequest, url]);

    return response;
};

export default useGet;