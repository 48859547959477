// Dependências
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

// Css
import classes from "./Button.module.css";

const Button = props => {
    const [backgroundColor, setBackgroundColor] = useState("");
    const [textColor, setTextColor] = useState("");

    // Define a cor do fundo e do texto do botão
    useEffect(() => {
        switch (props.color) {
            case "blue":
                setBackgroundColor("#3b3e51");
                setTextColor("#fff");
                break;
            case "purple":
                setBackgroundColor("#593196");
                setTextColor("#fff");
                break;
            case "red":
                setBackgroundColor("#c34858");
                setTextColor("#fff");
                break;
            case "green":
                setBackgroundColor("#218838");
                setTextColor("#fff");
                break;
            case "gray":
                setBackgroundColor("#6c757d");
                setTextColor("#fff");
                break;
            default:
                setBackgroundColor(props.color || "#593196");
                setTextColor(props.textColor || "#fff");
        };
    }, [props.color, props.textColor]);

    const ButtonComponent = (
        <div className={`${classes.Container} ${props.className}`}>
            <button
                className={`${classes.Button} ${props.disabled ? classes.Disabled : ""}`}
                onClick={props.disabled ? null : props.onClick}
                type={props.disabled ? "button" : props.type || "button"}
                style={{ backgroundColor: backgroundColor, color: textColor }}
            >
                {props.children}
            </button>
        </div>
    );

    return props.to ? <Link to={props.to}>{ButtonComponent}</Link> : ButtonComponent;
};

export default Button;