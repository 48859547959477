import { useEffect, useState } from 'react';

const getSavedValue = (key, initialValue) => {
    const savedValue = JSON.parse(sessionStorage.getItem(key));
    if (savedValue) return savedValue;
    if (initialValue instanceof Function) return initialValue();
    return initialValue;
};

const useSessionStorage = (key, initialValue) => {
    const [value, setValue] = useState(() => getSavedValue(key, initialValue));

    useEffect(() => {
        sessionStorage.setItem(key, JSON.stringify(value))
    }, [value, key]);

    return [value, setValue];
};

export default useSessionStorage;