// Dependências
import React, { memo } from "react";
import { useSelector } from 'react-redux';

// Components
import Checkbox from "../../../UI/Checkbox/Checkbox";

// Hooks
import useGet from "../../../../hooks/useGet";


const Permissoes = ({ onChange, selectedPermissoes }) => {
    const usuario = useSelector(state => state.requests["GET /usuario"]);

    const tiposPermissoes = useGet("/permissoes", { storage: true });

    return !tiposPermissoes?.length ? null : (
        <>
            <div style={{ textAlign: 'center' }}><h2 style={{ margin: ".5rem 0" }}>Permissões</h2></div>
            {tiposPermissoes.filter(tipoPermissao => usuario.permissoes.find(permissao => permissao.id === 1) ? true : tipoPermissao.id !== 1).map(tipoPermissao => (
                <div key={tipoPermissao.id} style={{ textAlign: 'justify' }}>
                    <h3 style={{ margin: "1.2rem 0 .5rem 0" }}>{tipoPermissao.nome}</h3>

                    {tipoPermissao.permissoes.filter(permissao => permissao.id !== 1).map(permissao => (
                        <div key={permissao.id}>
                            <Checkbox
                                key={permissao.id}
                                defaultChecked={selectedPermissoes?.includes(permissao.id)}
                                onChange={() => onChange({ ...permissao })}
                            >
                                <span style={{ padding: '0 .3rem' }}>{permissao.nome}.</span>
                            </Checkbox>

                            <span style={{ fontSize: ".8rem", fontStyle: 'italic', color: 'var(--hover-color)' }}>
                                {permissao.descricao}.
                            </span>
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

export default memo(Permissoes);