// Dependências
import React, { useCallback, useState } from "react";

// Components
import Button from "../../../UI/Button/Button";
import Input from "../../../UI/Input/Input";
import Modal from "../../../UI/Modal/Modal";
import Permissoes from "../Permissoes/Permissoes";

// Helpers
import {
    error as alertError,
    success as alertSuccess
} from "../../../../helpers/sweetAlert";
import {
    name as nameValidator,
    password as passwordValidator
} from "../../../../helpers/validator";

// Hooks
import useAxios from "../../../../hooks/useAxios";


const ModalCreateUsuario = ({ show, onClose }) => {
    const createUsuarioReq = useAxios("POST /usuarios", { unshift: "GET /usuarios" });

    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState({ nome: true, senha: true, confirmacaoSenha: true });

    const [nome, setNome] = useState("");
    const [senha, setSenha] = useState("");
    const [confirmacaoSenha, setConfirmacaoSenha] = useState("");
    const [newPermissoes, setNewPermissoes] = useState([]);

    const submitHandler = useCallback(() => {
        const isFormValid = () => {
            let formIsValid = true;
            let errorMessage = "";
            const copiaIsValid = { ...isValid };

            const setErrorMessage = message => {
                if (!errorMessage) errorMessage = message;
            };

            if (nameValidator(nome))
                copiaIsValid.nome = true;
            else {
                formIsValid = false;
                copiaIsValid.nome = false;
                setErrorMessage("Por favor, informe um nome válido.");
            };

            if (passwordValidator(senha)) {
                if (senha === confirmacaoSenha) {
                    copiaIsValid.senha = true;
                    copiaIsValid.confirmacaoSenha = true;
                } else {
                    formIsValid = false;
                    copiaIsValid.senha = false;
                    copiaIsValid.confirmacaoSenha = false;
                    setErrorMessage("As senhas não coincidem.");
                };
            } else {
                formIsValid = false;
                copiaIsValid.senha = false;
                copiaIsValid.confirmacaoSenha = false;
                setErrorMessage("A senha informada não é válida.");
            };

            if (!formIsValid)
                alertError(errorMessage);

            setIsValid(copiaIsValid);
            return formIsValid;
        };

        if (isFormValid()) {
            setIsLoading(true);
            createUsuarioReq({ body: { nome, senha, permissoes: newPermissoes } }).then(result => {
                setIsLoading(false);
                console.log(result)

                if (result.status === 201) {
                    setNome("");
                    setSenha("");
                    setConfirmacaoSenha("");
                    setNewPermissoes([]);
                    alertSuccess("Usuário criado com sucesso!");
                    onClose();
                };
            });
        };
    }, [confirmacaoSenha, createUsuarioReq, isValid, newPermissoes, nome, onClose, senha]);

    // Rodapé do modal
    const Footer = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button color="gray" onClick={onClose}>Cancelar</Button>
            <Button color="green" onClick={submitHandler} disabled={isLoading}>{isLoading ? "Carregando..." : "Salvar"}</Button>
        </div>
    );

    return (
        <Modal title="Criar novo usuário" show={show} onClose={onClose} footer={Footer}>
            <div style={{ margin: "1rem 0" }}>
                <h3 style={{ margin: "1.2rem 0 .5rem 0", textAlign: "center" }}>Informações de Login</h3>

                <Input defaultValue={nome} invalid={!isValid.nome} title="Nome" maxLength={60} onBlur={event => setNome(event.target.value)} />
                <Input defaultValue={senha} invalid={!isValid.senha} title="Senha" type="password" maxLength={60} onBlur={event => setSenha(event.target.value)} />
                <Input defaultValue={confirmacaoSenha} invalid={!isValid.confirmacaoSenha} title="Confirmar senha" maxLength={60} type="password" onBlur={event => setConfirmacaoSenha(event.target.value)} />


                <Permissoes onChange={selectedPermissao => {
                    if (newPermissoes.find(newPermissao => newPermissao.id === selectedPermissao.id))
                        setNewPermissoes(prevNewPermissoes => prevNewPermissoes.filter(prevPermissao => prevPermissao.id !== selectedPermissao.id));
                    else
                        setNewPermissoes(prevNewPermissoes => [...prevNewPermissoes, selectedPermissao]);
                }} />
            </div>
        </Modal>
    );
};

export default ModalCreateUsuario