const defaultStyles = `
    --background-color: hsl(180, 3%, 90%);
    --text-color: #000;

    --hover-color: hsl(0, 0%, 70%);
    --active-color: hsl(0, 0%, 40%);

    --primary-color: hsl(0, 0%, 75%);
    --secondary-color: hsl(0, 0%, 80%);
    --tertiary-color: hsl(0, 0%, 40%);

    --navbar-background-color: hsl(180, 3%, 18%);
    --navbar-text-color: hsl(0, 0%, 100%);

    --amarelo: hsl(45, 81%, 61%);
    --azul: hsl(210, 25%, 20%);
    --cinza: hsl(208, 7%, 46%);
    --roxo: hsl(264, 50%, 39%);
    --verde: hsl(102, 54%, 57%);
    --vermelho: hsl(352, 50%, 52%);
`;

const themes = {
    "light": `
        ${defaultStyles}
    `.replaceAll(/(?:\r\n|\r|\n)/g, "").replaceAll(" ", ""),
    "dark": `
        ${defaultStyles}
        --navbar-background-color: hsl(180, 3%, 12%);
        --navbar-text-color: hsl(0, 0%, 100%);

        --background-color: hsl(180, 3%, 18%); 
        --text-color: #fff; 

        --primary-color: hsl(180, 3%, 30%);
        --secondary-color: hsl(180, 3%, 20%);
        --tertiary-color: hsl(180, 3%, 40%);

        --hover-color: hsl(180, 3%, 25%);
        --active-color: hsl(264, 50%, 15%);         
    `.replaceAll(/(?:\r\n|\r|\n)/g, "").replaceAll(" ", "")
};

export default themes;