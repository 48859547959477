// Dependências
import React from 'react';

// Components
import Table from '../../components/UI/Table/Table';

// Css
import classes from "./InicioScreen.module.css";

// Helpers
import randomString from '../../helpers/randomString';


const InicioScreen = () => {
    return (
        <div className={classes.GridContainer}>

            <div className={classes.GridContainer}>
                Perguntas
            </div>

            <div className={classes.GridContainer}>
                Peças em pré-cadastro
            </div>

            <div className={classes.GridContainer}>
                Estatísticas do usuário
            </div>

            <div className={`${classes.GridContainer} ${classes.VendasContainer}`}>
                <div className={classes.TableContainer}>
                    <Table
                        fill
                        hoverble
                        downloadable
                        maxSize="15"
                        title="Últimas Vendas"
                        empty="Nenhuma venda registrada nos últimos dias."
                        headers={["ID", "Nome"]}
                    >
                        {[...Array(20)].map(() => (
                            <tr key={randomString(20)}>
                                <td>{randomString(2)}</td>
                                <td>{randomString(20)}</td>
                            </tr>
                        ))}
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default InicioScreen;