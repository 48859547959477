// Dependências
import React from 'react';

// Css
import classes from "./ImagemConta.module.css";

// Helpers
import readImageFiles from "../../../helpers/readImageFiles.js"


const ImagemConta = ({ imagem, updateImagem }) => {
    return (
        <div className={classes.ImageContainer}>
            <img src={imagem || "./images/default-user-image.jpg"} alt="" />

            {imagem ? (
                <label className={classes.Button} onClick={() => updateImagem({ body: { imagem: "" } })}>Apagar imagem</label>
            ) : (
                <label
                    className={classes.Button}
                    htmlFor="image-input"
                >Adicionar imagem</label>
            )}

            <input
                id="image-input"
                onChange={event => {
                    readImageFiles(event.target.files).then(result => {
                        const formatedFile = result[0]?.file
                        updateImagem({ files: { imagem: formatedFile } })
                    });
                }}
                type="file"
                style={{ display: "none" }} />
        </div >
    );
};

export default ImagemConta;