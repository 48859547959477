// Dependências
import React, { memo } from 'react';

// Components
import ColLink from "../../../UI/Table/ColLink/ColLink";

// Css
import classes from "./Sucata.module.css";

// Helpers
import { data as formatarData } from "../../../../helpers/formatar";


const Sucata = ({ sucata }) => {
    const sucatacreenLocation = `/estoque/sucatas/${sucata.id}`;

    return (
        <tr>
            <ColLink to={sucatacreenLocation}>{sucata.id}</ColLink>

            <ColLink to={sucatacreenLocation}>
                {sucata.fipe.marca.imagem ? (
                    <div className={classes.LogoMarcaWrapper}>
                        <img
                            src={sucata.fipe.marca.imagem}
                            className={classes.LogoMarca}
                            title={sucata.fipe.marca.nome}
                            alt={sucata.fipe.marca.nome} />
                    </div>
                ) : sucata.fipe.marca.nome || "-"}
            </ColLink>

            <ColLink to={sucatacreenLocation}>{sucata.fipe.modelo.nome || sucata.fipe.veiculo.nome}</ColLink>
            <ColLink to={sucatacreenLocation}>{sucata.fipe.ano.nome?.split(" ")[0] || "-"}</ColLink>
            <ColLink to={sucatacreenLocation}>{formatarData(sucata.dataCriacao, { convertMonthToString: true })}</ColLink>
        </tr>
    );
};

export default memo(Sucata);