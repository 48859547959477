import themes from "../../assets/themes";

import {
    SET_FONT_SIZE,
    SET_IS_LOADING,
    SET_THEME
} from "../actions/configurations";

const getPrefTheme = () => {
    if (!JSON.parse(localStorage.getItem("theme"))) {
        if (!window.matchMedia) {
            localStorage.setItem("theme", JSON.stringify("light"));
            return "light";
        };

        const defaultTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";

        localStorage.setItem("theme", JSON.stringify(defaultTheme));
        return defaultTheme;
    } else {
        const fontSize = JSON.parse(localStorage.getItem("font-size")) || "16px";
        const theme = JSON.parse(localStorage.getItem("theme")) || themes.light
        document.getElementById('html').setAttribute('style', themes[theme] + `font-size:${fontSize};`);
        return theme;
    };
};

const getPrefFontSize = () => {
    if (!JSON.parse(localStorage.getItem("font-size")))
        return "16px";
    else {
        const fontSize = JSON.parse(localStorage.getItem("font-size")) || "16px"
        return fontSize;
    };
};


const initialState = {
    theme: getPrefTheme(),
    fontSize: getPrefFontSize(),
    isLoading: false
};

const configuration = (state = initialState, action) => {
    switch (action.type) {
        case SET_FONT_SIZE:
            return { ...state, fontSize: action.fontSize };
        case SET_IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case SET_THEME:
            return { ...state, theme: action.theme };
        default:
            return state;
    }
};

export default configuration;