// Dependências
import React, { memo } from 'react';

// Components
import ColLink from "../../../UI/Table/ColLink/ColLink";

// Css
import classes from "./Peca.module.css";

// Helpers
import { data as formatarData } from "../../../../helpers/formatar";


const Peca = ({ peca }) => {
    const pecaScreenLocation = `/estoque/pecas/${peca.id}`;

    return (
        <tr>
            <ColLink to={pecaScreenLocation}>{peca.id}</ColLink>
            <ColLink to={pecaScreenLocation}>{peca.arquetipo.nome || "-"}</ColLink>

            <ColLink to={pecaScreenLocation}>
                {peca.sucata.fipe.marca.imagem ? (
                    <div className={classes.LogoMarcaWrapper}>
                        <img
                            src={peca.sucata.fipe.marca.imagem}
                            className={classes.LogoMarca}
                            title={peca.sucata.fipe.marca.nome}
                            alt={peca.sucata.fipe.marca.nome} />
                    </div>
                ) : peca.sucata.fipe.marca.nome || "-"}
            </ColLink>

            <ColLink to={pecaScreenLocation}>{peca.sucata?.fipe?.modelo?.nome || peca.sucata?.fipe?.veiculo?.nome || "-"}</ColLink>
            <ColLink to={pecaScreenLocation}>{formatarData(peca.dataCriacao, { convertMonthToString: true })}</ColLink>
        </tr>
    );
};

export default memo(Peca);